.about-project {
  padding: 81px 0 100px;
  width: 100%;
  margin: 0 auto;
  background: url("../../images/cards/AboutProject/bgTile.svg") repeat;

  &__content-wrap {
    display: flex;
    justify-content: space-between;
    max-width: 1119px;
    margin: 0 auto;
  }
  &__content {
    h1 {
      color: $white;
      font-weight: bold;
      font-size: 44px;
      line-height: 53px;
      margin: 0 0 36px;
    }
  }
  &__list {
    margin: 0 0 47px;
    padding-left: 31px;
    list-style: none;
    li {
      position: relative;
      color: $white;
      font-size: 18px;
      line-height: 28px;
      &:before {
        position: absolute;
        left: -31px;
        display: block;
        content: "";
        width: 17px;
        height: 28px;
        background: url("../../images/cards/AboutProject/listElem.svg");
      }
    }
  }
  &__btn {
    @include btn-primary;
  }
  &__image {
    margin: 0 15px 0 0;
    img {
      width: 100%;
    }
  }

  @include maxWidth(1080px) {
    &__content-wrap {
      flex-direction: column;
      align-items: center;
    }
    &__content {
      margin-bottom: 40px;
    }
    &__btn {
      margin: 0 auto;
    }
  }

  @include maxWidth(500px) {
    padding: 30px 0 40px;;
    h1 {
      font-size: 34px;
      line-height: 38px;
    }
    &__list {
      li {
        font-size: 15px;
      }
    }
  }
}