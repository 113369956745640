

.landing__networks {
  width: 60px;
  bottom: -40px;
  right: 0;
  top: unset;
  position: fixed;
  z-index: 3;
}
.networks__link-img {
  width: 60px;
  transition: opacity 0.5s;
}
.networks__link-img:hover {
  width: 60px;
  transition: opacity 0.5s;
  opacity: 0.6;
}

@include tablet {
  .landing__networks {
    width: 90px;
    top: 65%;
    bottom: unset;
  }
  .networks__link-img {
    width: 80px;
  }
  .networks__link-img:hover {
    width: 80px;
  }
}
