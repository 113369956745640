@import "vars";
@import "mixins";
@import "../assets/styles/helpers";
@import "layouts/main";
@import "common/general";
@import "ui/index.scss";
@import "modules/index.scss";
@import "landing/index";
@import "../assets/styles/main";

body {
  background: $main_bg;
  overflow-x: auto;
}

html,
body,
#root {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

* {
  font-family: $titleFont, sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
}

body.modal-opened {
  overflow: hidden;
}

.landing-pages {
  h1 {
    margin: 0;
    font-family: $titleFont, sans-serif;
    text-align: center;
    font-size: 35px;
    font-weight: 900;
    line-height: 41px;
    color: $white;
  }

  h2 {
    margin: 0;
    font-family: $titleFont, sans-serif;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    line-height: 36px;
    color: $white;
  }

  h3 {
    margin: 0;
    font-family: $titleFont, sans-serif;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    line-height: 28px;
    color: $white;
  }

  p {
    font-family: $mainSansSerif;
    margin: 0;
  }

  form {
    input,
    textarea {
      margin: 20px auto 0;
      padding: 14px 24px;
      width: 100%;
      background: $defaultWhite;
      height: 45px;
      border: none;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px;
      color: $titleBlack;
      font-size: 14px;
      line-height: 16px;
      box-shadow: 0 15px 30px $slideBoxShadow;
      resize: none;

      &::placeholder {
        display: inline;
        vertical-align: top;
      }

      &:focus,
      :active {
        outline: none;
        padding: 12px 22px;
        border: 2px solid $borderBlue;
      }
    }
  }

  section {
    margin: 0 auto 101px;
  }
}

.datepicker {


  .anticon {
    margin: 0 5px;
  }

  .ant-picker {
    width: 80px;
    padding: 0;

    input {
      text-align: center;
      padding: 0 4px;
    }
  }

}