.sidebar {
  min-height: 100vh;

  &__trigger {
    position: absolute;
    bottom: 50px;
    right: -15px;
    padding: 3px 7px;
    background: $alwaysWhite;
    border-radius: 100%;
    display: block;
    border: 5px solid $main_bg;
    cursor: pointer;
  }

  &__text {
    &__margin-left {
      margin-left: 24px;
    }

    &-username {
      position: absolute;
      top: -7px;
      left: 50px;

      &:hover {
        color: unset;
      }
    }
  }
  .ant {
    &-menu {
      &-item {
        padding: 0 3px 24px 0;
      }
      &-inline {
        &-collapsed {
          .ant {
            &-menu {
              &-item {
                text-align: center;
                .sidebar {
                  &__text {
                    max-width: 0;
                    opacity: 0;
                    display: inline-block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .sidebar-language-flag {
    height: 24px;
  }


}