.our-benefits {
  margin: 0 auto 75px;

  h2 {
    margin: 0 0 45px;
  }
  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(397px, 1fr));
    grid-gap: 24px;
    margin: 0 0 35px;
  }
  button {
    @include btn-primary;
    margin: 0 auto;
  }

  @include maxWidth(500px) {
    h2 {
      margin: 0 0 15px;
    }
    &__cards {
      grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
      grid-gap: 15px;
    }
  }
}

.benefit-card {
  position: relative;
  justify-self: center;
  filter: drop-shadow(0px 15.7033px 36.641px rgba(28, 51, 56, 0.04));
  background: transparent;
  max-width: 397px;
  height: 108px;

  &__content {
    position: relative;
    top: 8px;
    left: 0;
    display: flex;
    justify-content: space-between;
    height: 100px;
    width: 100%;
    background: $grayGradient2;
    border-radius: 22px;
    padding: 30px 27px 31px;
  }
  &__img-cont {
    position: relative;
    top: -35px;
    width: 110px;
    height: fit-content;
    margin: 0 52px 0 0;
    text-align: center;
    flex-shrink: 0;
    img {
      margin: 0 auto;
    }
  }
  &__title {
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    color: $grayText;
  }

  @include maxWidth(500px) {
    max-width: 290px;

    &__content {
      padding: 5px;
      align-items: center;
    }
    &__img-cont {
      top: -12px;
      margin: 0 14px 0 0;
    }
    &__title {
      height: fit-content;
    }
  }
}