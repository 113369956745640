.manageable-list-card {
  margin-bottom: 40px;
  &__top {
    display: flex;
    align-items: center;

    .status-label {
      margin-right: 15px;
    }

    .ant-typography {
      margin-bottom: 0;
    }

    .ant-btn {
      margin-left: auto;

      .anticon {
        margin-right: 10px;
      }
    }
  }
}

.manageable-user-list {
  + * {
    margin-top: 50px;
  }

  &__top {
    display: flex;
    align-items: center;

    .ant-btn {
      + .ant-btn {
        margin-left: 10px;
      }
    }
    .ant-typography {
      margin-bottom: 0;
      margin-right: auto;
    }

    .anticon {
      margin-right: 10px;
    }
  }

  &__content {

    padding-top: 30px;


    td {
      &:nth-child(1),
      &:nth-child(2) {
        width: 50px;
      }

      &:last-child {
        width: 100px;
      }
    }
  }
}

.manageable-list-info {

  &__top {
    display: flex;
    padding-bottom: 25px;
    .ant-btn {
      margin-left: auto;
    }
  }

  &__controls {
    display: flex;
    flex-wrap: wrap;
    padding-top: 15px;
    > * {
      margin-bottom: 10px;
      text-align: center;
      justify-content: center;
      flex: 1;

      &:nth-child(1) {
        margin-right: 10px;
        flex: none;
      }
    }
  }
}

.affiliate-landing-pages-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;

  > * {
    width: 20%;
    padding: 0 10px;
    padding-bottom: 10px;
    height: 370px;

    @include media('>1920px') {
      width: calc(100% / 7);
    }
    @include media('>2500px') {
      width: calc(100% / 12);
    }

    @include media('<=1024px') {
      width: calc(100% / 3);
    }
    @include media('<568px') {
      width: 100%;
    }
  }
}