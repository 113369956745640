.auth-wrapper {
  @include flex($type: center, $direction: column);
  min-height: 100vh;
  padding: 60px 0;
  &__content {
    width: 500px;
    max-width: 95%;
  }

  .ant-card-head-title {
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    padding-bottom: 0;
  }
}

.auth-form {


  &__footer {
    @include flex($direction: column, $justify-content: center,);
    text-align: center;
  }

  .ant-btn {
    margin: 0 auto;
    margin-bottom: 15px;
    width: 150px;
    justify-content: center;
  }

  .ant-form-item-explain {
    font-size: 12px;
    margin-top: 5px;
  }

  .ant-form-item-label > label {
    @include media("<568px") {
      height: auto;
    }
  }
}

.recaptcha, .auth-check {

  .ant-form-item-control-input-content {
    @include flex($justify-content: center);
  }
  .ant-form-item-explain {
    text-align: center;
  }
}
