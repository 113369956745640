.nav-cards {
  position: relative;
  top: -90px;
  margin: 0 0 71px;
  padding: 0;
  display: flex;
  z-index: 1;

  @include maxWidth($media_lg) {
    flex-wrap: wrap;
    justify-content: space-between;
    top: 0;
    margin-top: 50px;
  }
  @include maxWidth(500px) {
    margin: 0 0 -20px;
  }
}

.nav-card {
  +.nav-card {
    margin-left: 10px;

    @include maxWidth($media_lg) {
      margin-left: 0;
    }
  }
  display: flex;
  justify-self: center;
  flex-direction: column;
  justify-content: center;
  padding: 25px 16px;
  height: 108px;
  border-radius: 12px;
  flex: 1;

  @include maxWidth($media_lg) {
    flex: none;
    width: calc(50% - 5px);
    margin-bottom: 10px;
  }
  @include maxWidth($media_xs) {
    width: 100%;
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__title {
    font-size: 15px;
    line-height: 18px;
    font-weight: bold;
    color: $white;
    margin: 0 0 8px;
  }

  &__btn {
   @include btn-secondary;
    width: fit-content;
    padding: 8.5px 18px;
    font-size: 11px;
    line-height: 13px;
    font-family: $fontPrimary;
  }
}
