.how-it-works {
  max-width: 1239px;
  margin: 0 auto 86px;
  padding: 0;

  h2 {
    margin: 0 0 25px;
  }
  &__cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(262px, 1fr));
    grid-gap: 30px;
    margin: 0 auto 38px ;
  }
  button {
    @include btn-primary;
    margin: 0 auto;
  }
}

.how-it-works-card {
  position: relative;
  justify-self: center;
  max-width: 262px;
  height: 261px;
  text-align: center;
  flex-grow: 1;
  margin: 0;

  &:not(:last-child) {

    &:after {
      //1255px width
      @include maxWidth(1254px) {
        display: none;
      }
      position: absolute;
      top: 134.5px;
      right: -20px;
      content: "";
      width: 11px;
      height: 22px;
      display: block;
      background: url("../../images/cards/HowItWorks/arrow.svg") no-repeat center;
    }
  }

  &__content {
    position: relative;
    top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 50px 36px;
    width: 100%;
    height: 245px;
    background: $grayGradient;
    border-radius: 10px;
  }
  &__img-cont {
    position: relative;
    top: -35px;
    img {

    }
  }
  &__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: $grayText;
  }
}