.commissions {
  max-width: 1219px;
  margin: 0 auto 91px;
  padding: 0;

  &__wrap {
    min-height: calc(100vh - 82px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 78px;
  }

  &__cards {
    display: flex;
    justify-content: center;
    align-items: center;

    @include maxWidth(1254px) {
      flex-direction: column;
    }
  }
}

.commission-card {
  position: relative;
  width: 380px;
  height: 445px;
  background: transparent;
  &:nth-child(1) {
    margin: 0 -10px 0 0;
  }
  &:nth-child(2) {
    z-index: 1;
    .commission-card__content {
      top: 49px;
      height: 418px;
    }
    .commission-card__img-cont {
      top: -19px;
    }
  }
  &:nth-child(3) {
    margin: 0 0 0 -10px;
  }

  &__content {
    position: relative;
    top: 70px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px 43px;
    border-radius: 15px;
    background: $grayGradient;
    text-align: center;
  }
  &__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: $grayText;
  }
  &__description {
    font-size: 18px;
    line-height: 21px;
    color: $white;
    font-family: $mainSansSerif;
  }
  &__img-cont {
    position: relative;
    top: -50px;
  }
  &__btn {
    @include btn-primary;
  }

  @include maxWidth(1254px) {
    margin-top: 25px !important;
    flex-direction: column;

    &:nth-child(1) {
      margin: 0;
    }

    &:nth-child(3) {
      margin: 0;
    }
  }

  @include maxWidth(500px) {
    width: 280px;
    height: 330px;
    &:nth-child(2) {
      z-index: 0;
      .commission-card__content {
        top: 40px;
        height: 290px;
      }
      .commission-card__img-cont {
        top: -30px;
      }
    }

    &__content {
      top: 40px;
      padding: 0 15px 16px;
      height: 300px;
    }

    &__title {
      font-size: 16px;
      line-height: 20px;
    }
    &__description {
      font-size: 16px;
      line-height: 19px;
    }
    &__img-cont {
      top: -30px;
      img {
        max-height: 110px;
      }
    }
  }
}

.commission-item-card {
  position: relative;
  justify-self: center;
  filter: drop-shadow(0px 15.7033px 36.641px rgba(28, 51, 56, 0.04));
  background: transparent;
  margin: auto 20px;
  color: $white;

  &__content {
    position: relative;
    top: 8px;
    left: 0;
    width: 100%;
    background: $grayGradient2;
    border-radius: 22px;
    padding: 30px 27px 31px;
  }
  &__img-cont {
    display: flex;
    align-content: center;
    justify-content: center;
    position: relative;
    height: fit-content;
    text-align: center;
    flex-shrink: 0;
    img {
      margin: 10px auto;
    }
  }

  @include tablet {
    margin: auto 100px;

    &__content {
      padding: 5px;
      align-items: center;
    }
  }
}