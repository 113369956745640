.landing-pages {
  .header {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 14px 20px 14px 64px;
    width: 100%;
    height: 72px;
    font-family: $mainSansSerif, sans-serif;
    z-index: 5;
    background: transparent;

    .btn__mobile-burger {
      display: none;
    }

    &__navigation-wrap {
      display: flex;
      align-items: center;

      &--modal {
        display: block;

        ul.nav-menu {
          margin: 0;
          padding: 0;
          flex-direction: column;

          li {
            margin: 0 auto 15px !important;
          }

          a {
            font-size: 40px;
            line-height: 45px;

            @include maxWidth($media_xs) {
              font-size: 21px;
              line-height: 1.3;
            }
          }
        }

        .language-dropdown {
          margin: 0;
          font-size: 40px;
          line-height: 45px;
          @include maxWidth($media_xs) {
            font-size: 21px;
            line-height: 1.3;
          }
          &__flag {
            width: 35px;
            height: 35px;
            @include maxWidth($media_xs) {
              width: 20px;
              height: 20px;
            }
          }
        }

        div.header__button-wrap {
          position: absolute;
          left: 0;
          bottom: 60px;
          padding: 0 15px;
          margin: 0;
          width: 100%;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          .btn {
            width: 100%;
            margin: 0;
          }

          .header__sign-up {
            margin: 0 0 10px;
          }
        }
      }
    }

    .nav-menu {
      display: flex;
      align-items: center;
      margin: 0 30px 0 0;
      list-style: none;

      &__item {
        &:not(:last-child) {
          margin: 0 30px 0 0;
        }
      }

      &__link {
        color: $white;
        font-weight: 600;
        font-size: 15px;
        line-height: 21px;

        &:hover {
          color: $primaryYellow;
        }
      }
    }

    &__logo {
      &-link, &-cont {
        display: flex;
        align-items: center;
      }
    }

    &__button-wrap {
      display: flex;

      .header__sign-up {
        @include btn-primary;
        padding: 13px 20px;
        margin: 0 12px 0 0;
      }

      .header__login {
        @include btn-hollow;
        padding: 12px 19px;
      }
    }

    &__mobile-menu {
      display: none;
      justify-content: space-between;
      align-items: center;
      width: auto;
      font-weight: 400;
      color: $white;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
    }

    @include maxWidth(860px) {
      .btn__mobile-burger {
        display: block;
        padding: 14px 6px;
      }
      &__mobile-menu {
        display: flex;
      }
      &__navigation-wrap {
        display: none;

        &--modal {
          display: block;
        }
      }
    }

    @include maxWidth(500px) {
      padding: 14px 15px;
    }
  }
}