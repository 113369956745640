

.faq__bg-layer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 82px);
  padding-top: 78px;

  .header {
    padding-top: 10px;
    margin: 0 20px 67px;
    height: unset;
  }

  @media (max-width: 1440px) {
    section.faq {
      margin: 0;
    }
  }

  .faq {
    flex-grow: 1;
    padding: 0 20px;


    .faq-card {
      position: relative;
      width: 25vw;
      height: 300px;
      background: transparent;
      margin: 0 10px;
      max-width: 310px;

      >.selected {
        box-shadow: 0 15px 50px $btnBoxShadowTint;
        color: $mainOrange;
      }

      &__content {
        position: relative;
        top: 70px;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px 43px;
        border-radius: 15px;
        background: $grayGradient;
        text-align: center;
        height: 240px;
      }
      &__title {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: $grayText;
      }
      &__img-cont {
        position: relative;
        top: -50px;
        height: 150px;
      }

      @include maxWidth(1254px) {
        margin-top: 25px !important;
        flex-direction: column;
      }

      @include maxWidth(500px) {
        height: 330px;

        &__content {
          top: 40px;
        }

        &__title {
          font-size: 16px;
          line-height: 20px;
        }
        &__description {
          font-size: 16px;
          line-height: 19px;
        }
        &__img-cont {
          top: -30px;
          img {
            max-height: 110px;
          }
        }
      }
    }

    h2 {
      font-size: 35px;
      line-height: 41px;
      color: $titleBlack;
      margin-bottom: 30px;
      text-align: left;
    }

    &__tile-cont {
      display: flex;
      justify-content: space-around;
      margin-bottom: 40px;
      padding-top: 40px;

      .tile {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 85px;
        height: 110px;
        padding: 20px 0 15px;
        border-radius: 25px 0 25px 0;
        background: $defaultWhite;
        box-shadow: 0 15px 50px $slideBoxShadow;

        &:hover {
          box-shadow: 0 15px 50px $btnBoxShadowTint;
          color: $mainOrange;
        }

        &__img-cont {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        h4 {
          margin: 0;
          font-family: $mainSansSerif;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          font-weight: 600;
          color: inherit;
        }
      }

      .tile.selected {
        box-shadow: 0 15px 50px $btnBoxShadowTint;
        color: $mainOrange;
      }
    }

    h3 {
      font-size: 30px;
      line-height: 35px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      text-align: left;
      font-weight: 800;
      margin-bottom: 30px;
    }

    &__collapsible-cont {
      display: none;
      flex-direction: column;

      .collapsible-item {
        position: relative;
        margin-bottom: 10px;
        padding: 20px 44px 24px 20px;
        background: $grayBackground;
        box-shadow: 0 15px 50px $slideBoxShadow;

        &:first-child {
          border-radius: 15px 15px 0 0;
        }

        &:last-child {
          margin-bottom: 0;
          border-radius: 0 0 15px 15px;
        }

        &__question {
          display: inline-block;
          margin: 0;
          font-family: foco, sans-serif;
          font-size: 25px;
          line-height: 28px;
          text-align: left;
          font-weight: 600;
          color: $white;
        }

        .expand-collapsible {
          position: absolute;
          top: 20px;
          right: 10px;
          width: 26px;
          height: 26px;

          &:before, &:after {
            display: block;
            content: '';
            width: 24px;
            height: 0;
            border: 1px solid $primaryYellow;
          }

          &:after {
            transform: rotate(90deg);
          }

          &:before {
            position: relative;
            top: 2px;
          }
        }

        &__answer {
          max-height: 0;
          overflow: hidden;
          background-color: $defaultWhite;
          font-family: $mainSansSerif;
          color: $defaultWhite;
          font-size: 16px;
          line-height: 19px;
        }
      }

      .collapsible-item.expanded {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: baseline;
        background-color: $grayBackground;

        .collapsible-item__question {
          margin-bottom: 24px;
          color: $defaultWhite;

          &::selection {
            background: $mainOrange;
          }
        }

        .expand-collapsible {
          &:before, &:after {
            border-color: $defaultWhite;
          }
        }

        .collapsible-item__answer {
          display: inline-block;
          max-height: 700px;
          transition: max-height 0.7s ease-out;
          background-color: $grayBackground;

          &::selection {
            background: $mainOrange;
          }
        }
      }
    }

    &__collapsible-cont.selected {
      display: flex;
    }
  }

  .footer {
    padding-top: 0;
    min-height: unset;
    background: none;
  }
}