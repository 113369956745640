.main-banner {
  position: absolute;
  margin: 0;
  width: 100%;
  height: $mainBannerHeight;
  min-height: $mainBannerMinHeight;
  background: transparent;
  z-index: 1;

  .slick-slider {
    .slick-dots {
      display: inline-block !important;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 110px;
      z-index: 1;
      width: auto;

      @include maxWidth($media_lg) {
        bottom: -20px;
      }
      li {
        width: fit-content;
        height: fit-content;
        margin: 0;
        &:not(:last-child) {
          margin: 0 5px 0 0;
        }
        &.slick-active {
          button {
            width: 68px;
            background: $primaryYellow;
          }
        }

        button {
          width: 23px;
          height: 8px;
          border-radius: 21px;
          background: $dark;
          padding: 4px;

          @include maxWidth($media_lg) {
            background: lighten($dark, 15%);

          }
          &:before {
            display: none;
          }
        }
      }
    }
  }

  @include maxWidth(500px) {
    &:after {
      height: 81px;
    }
    .slick-slider {
      .slick-dots {
        bottom: 70px;
      }
    }
  }
}

.banner-slide {
  $banners-path: '../../assets/images/outer-pages/main-banners/';
  position: relative;
  padding: 154px 61px 58px;
  height: $mainBannerHeight;
  min-height: $mainBannerMinHeight;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: no-repeat center / cover;


    &--0 {
      background-image: url($banners-path + "banner_0.jpg");

      @include media('<568px') {
        background-image: url($banners-path + "banner_0_mobile.jpg");
      }
    }
    &--1 {
      background-image: url($banners-path + "banner_1.jpg");

      @include media('<568px') {
        background-image: url($banners-path + "banner_1_mobile.jpg");
      }
    }
    &--2 {
      background-image: url($banners-path + "banner_2.jpg");

      @include media('<568px') {
        background-image: url($banners-path + "banner_2_mobile.jpg");
      }
    }
    &--3 {
      background-image: url($banners-path + "banner_3.jpg");

      @include media('<568px') {
        background-image: url($banners-path + "banner_3_mobile.jpg");
      }
    }
  }
  &__title {
    margin: 0 0 8px;
    font-size: 44px;
    line-height: 53px;
    font-weight: bold;
    color: $white;
    position: relative;
    z-index: 5;
  }
  &__sub-title {
    margin: 0 0 30px;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: $white;
    opacity: 0.9;
    position: relative;
    z-index: 5;
  }
  &__btn {
    @include btn-primary;
    padding: 14px 20px;
    position: relative;
    z-index: 10;
  }

  @include media('<567px') {
    padding: 90px 15px;
    height: auto;
    &__title {
      font-size: 32px;
      line-height: 38px;
    }
    &__sub-title {
      font-size: 16px;
      line-height: 24px;
      width: 60%;
    }
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, .2);
    z-index: 5;
  }
  &:after {
    position: absolute;
    bottom: 0;
    z-index: 2;
    left: 0;
    display: block;
    content: "";
    width: 100%;
    height: 150px;
    background: $darkGradient;

  }
}